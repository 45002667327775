<template>
  <div class="point-center" ref="centerp"></div>
  <div class="slider-bar" ref="sliderBar">
    <div class="slider-bar2" :style="sliderBarStyle2"></div>
    <div
      id="bar1"
      class="bar"
      @touchstart="touchStart"
      @touchmove="touchMove"
      @touchend="touchEnd"
      :style="barStyle"
      ref="bar"
    >
      <div @touchstart="returnFalse">+</div>
      <div @touchstart="returnFalse">-</div>
    </div>
  </div>

  <button @touchstart="touchHome" @click="touchHome" class="home"></button>
</template>

<script lang="js">
import {  defineComponent, ref ,reactive ,onMounted } from "vue";

  //节流
  function throttle (fn, delay) {
    let isThtottle = ref(true)
    return () => {

      if (!isThtottle.value) return
      isThtottle.value = false
      setTimeout(() => {
        fn()
        isThtottle.value = true
      }, delay)
    }
  }
export default defineComponent({
  name: "zoomBar",
  emits:['enlarge','narrow','home'],
  components:{},
  setup(props,{emit}) {
     const value=ref(0)
     const barStyle=reactive({});
     const sliderBarStyle2=reactive({});
     const sliderBar=ref(null);
     const bar = ref(null);
     const centerp = ref(null);
     const isMov=ref(false);
     let oldTop=0,top=0;
     onMounted(()=>{
        barStyle.left=sliderBar.value.offsetWidth/2+'px';
        barStyle.top=sliderBar.value.offsetHeight/2+'px';
        oldTop=sliderBar.value.offsetHeight;
        console.log(centerp.value.offsetLeft,sliderBar.value.offsetTop)
        // sliderBarStyle['background-color']='none'
        // console.log(barStyle.left)
     })
    //  document.getElementById('bar1')
     const touchStart=(event)=>{
        event.preventDefault();
        event.stopPropagation();
        console.log('开始滑动')
        // console.log(event)
        sliderBarStyle2.height='100%'
        oldTop=event.changedTouches[0].clientY-sliderBar.value.offsetTop;
        isMov.value=true;
     }
     //创建节流
     const emitNarrow=throttle(()=>{
          if(top<oldTop){
              // console.log({x:centerp.value.offsetLeft,y:centerp.value.offsetTop})
                console.log('放大')
                emit('enlarge',{x:centerp.value.offsetLeft,y:centerp.value.offsetTop})
              }else{
                console.log('缩小')
                emit('narrow',{x:centerp.value.offsetLeft,y:centerp.value.offsetTop})
              }
            oldTop=top;
          },100)
    //  const emitEnlarge=throttle(()=>{
    //       // console.log('节流')

    //       },100)
     const touchMove=(event)=>{
        event.preventDefault();
        event.stopPropagation();
        // console.log('滑动中')
        // console.log(event.changedTouches[0])
        // console.log(sliderBar.value.offsetTop)
        top=event.changedTouches[0].clientY-sliderBar.value.offsetTop;
        // console.log(event.changedTouches[0].clientY,sliderBar.value.offsetTop)
        // const centerY=top+(bar.value.offsetHeight/2)
        if(top<0||top>sliderBar.value.offsetHeight){
          return
        }
        emitNarrow()
        barStyle.top=top+"px"
     }
      // setInterval(()=>{
      //      emit('enlarge',{x:centerp.value.offsetLeft,y:centerp.value.offsetTop})
      // },2000)
     const touchEnd=(event)=>{
      event.preventDefault();
      event.stopPropagation();
        console.log('滑动结束')
        // console.log(event)

        isMov.value=false;
        let speed=0,curentxBysliderBar=parseInt(barStyle.top.substring(0,barStyle.top.indexOf('px'))),a=1,t=0;
        // 2gh=v²
        if(curentxBysliderBar>sliderBar.value.offsetHeight/2) {
          speed=(t)=>-1/2*15*Math.pow(t,2);
          a=-1;
        } else {
          speed=(t)=>1/2*15*Math.pow(t,2);
          a=1;
        }
        const timer=setInterval(()=>{
              if(isMov.value||(a===-1&&curentxBysliderBar<sliderBar.value.offsetHeight/2)||(a===1&&curentxBysliderBar>sliderBar.value.offsetHeight/2)){
                curentxBysliderBar=sliderBar.value.offsetHeight/2
                barStyle.top=curentxBysliderBar+"px"
                sliderBarStyle2.height='5px'
                clearInterval(timer)
              }
              t=t+10;
              curentxBysliderBar=curentxBysliderBar+speed(t/1000);
              barStyle.top=curentxBysliderBar+"px"
        },10)
     }
     const touchHome=()=>{
          emit('home',    {
                "home": false,
                "height": 142.713134765625,
                "latitude": 32.92095947265625,
                "longitude": 119.58149719238281,
                "heading": -13.232078281157825,
                "pitch": -45.30586654168997,
                "range": 1190.9476246525346,
                "throughtime": 1,
                "vaild": true
        })
     }
    document.addEventListener('click', (event) => {
      // do something
      console.log(event)
  })
     onMounted(()=>{
     })
     const returnFalse=()=>{
          return false
     }
      return { value,touchStart,touchMove,touchEnd,barStyle,sliderBar,bar,sliderBarStyle2,centerp,touchHome,returnFalse };
    },
});
</script>

<style scope>
.slider-bar {
  position: fixed;
  right: 8%;
  top: 15%;
  width: 1%;
  height: 600px;
  background: none;
  /* background-color: white; */
  /* transform: translateY(-50%); */
  /* opacity: 0.3; */
}
.slider-bar2 {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 5px;
  transition: height 0.5s;
  background-color: rgba(20, 20, 20, 0.8);
  transform: translateY(-50%);
  /* opacity: 0.3; */
}
.point-center {
  position: absolute;
  width: 1px;
  height: 1px;
  background-color: white;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  /* z-index: -999; */
}
.bar {
  position: absolute;
  /* top: 0px; */
  width: 100px;
  height: 100px;
  background: white;
  color: black;
  text-align: center;
  font-weight: 400;
  font-size: 90px;
  line-height: 35px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 20px 8px 0 8px;
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
  border-radius: 30%;
  transform: translate(-50%, -50%);
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -khtml-user-select: none; /*早期浏览器*/
  -moz-user-select: none; /*火狐*/
  -ms-user-select: none; /*IE10*/
  user-select: none;
}
.home {
  position: fixed;
  right: 3%;
  top: 55%;
  width: 100px;
  height: 100px;
  border-radius: 10%;
  /* background: white; */
  transform: translateY(-50%);
  border: none;
  outline: none;
  background-image: url(../static/fz.png);
  /* opacity: 0.8; */
}
</style>
